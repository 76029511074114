<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(this.action == 'insert' || this.action == 'copy')">{{ $t("PACKAGE-COMMODITY-LIST.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(this.action == 'update')">{{ $t("PACKAGE-COMMODITY-LIST.EDIT-ITEM") }}</MDBCardHeader>
        <MDBCardText class="pt-3">
          <MDBTabs v-model="page">
            <MDBTabNav fill>
              <MDBTabItem tabId="general">設定</MDBTabItem>
              <MDBTabItem tabId="commodity">產品</MDBTabItem>
              <MDBTabItem tabId="price">價格</MDBTabItem>
            </MDBTabNav>
            <MDBTabContent>
              <MDBTabPane tabId="general" class="pt-3">
                <MDBRow class="g-3">
                  <MDBCol md="12">
                    <div>
                      <label class="form-label text-dark">{{ $t(`COMMON.SORT`) }}</label>
                      <input type="number" class="form-control" :placeholder="$t(`COMMON.SORT`)" v-model="Form.sort.value">
                      <span class="text-danger">{{ sortError }}</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="12">
                    <div class="mb-3">
                      <label class="form-label text-dark">{{ $t('COMMON.PRODUCTTYPE') }}<span class="text-danger">*</span></label>
                      <select class="form-select" v-model="Form.type.value">
                        <option value="3">商品</option>
                        <option value="2">課程</option>
                      </select>
                      <span class="text-danger">{{ typeError }}</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="12">
                    <div class="mb-3">
                      <div>
                        <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.COMMODITYSTATUS') }}<span class="text-danger">*</span></label>  
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="status" v-bind:id="`${Action}-status-1`" value="1" v-model="Form.status.value">
                        <label class="form-check-label" v-bind:for="`${Action}-status-1`">
                          {{ $t('COMMON.PUTON') }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="status" v-bind:id="`${Action}-status-0`" value="0" v-model="Form.status.value">
                        <label class="form-check-label" v-bind:for="`${Action}-status-0`">
                          {{ $t('COMMON.PUTOFF') }}
                        </label>
                      </div>
                      <span class="text-danger">{{ statusError }}</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="12">
                    <!-- <div class="mb-3">
                      
                      <input type="text" class="form-control" name="name" :placeholder="$t('COMMON.PRODUCTNAME')" v-model="Form.name.value">
                      <span class="text-danger">{{ nameError }}</span>
                    </div> -->
                    <label class="form-label text-dark">{{ $t('COMMON.PRODUCTNAME') }}<span class="text-danger">*</span></label>
                    <div>
                      <label class="form-label text-dark">{{ ($t(`COMMON.NAME`)) }}</label>
                      <input type="text" class="form-control" :placeholder="$t(`COMMON.PRODUCTNAME`)" v-model="product_name">
                      <span class="text-danger">{{ errors[`name.tw.name`] }}</span>
                    </div>
                    <!-- <template v-for="(name, lang) in Form.name.value" :key="lang">
                      <div>
                        <label class="form-label text-dark">{{ ($t(`LANG.${lang}`)+$t(`COMMON.NAME`)) }}</label>
                        <input type="text" class="form-control" name="name" :placeholder="($t(`LANG.${lang}`)+$t(`COMMON.NAME`))" v-model="Form.name.value[lang].name">
                        <span class="text-danger">{{ nameError }}</span>
                      </div>
                    </template> -->
                  </MDBCol>
                  <MDBCol md="12">
                    <label class="form-label text-dark">{{ $t('COMMON.USEVERSION') }}<span class="text-danger">*</span></label>
                    <template v-for="(name, lang) in Form.version.value" :key="lang">
                      <div>
                        <label class="form-label text-dark">{{ ($t(`LANG.${lang}`)+$t(`COMMON.NAME`)) }}</label>
                        <input type="text" class="form-control" :placeholder="($t(`LANG.${lang}`)+$t(`COMMON.NAME`))" v-model="Form.version.value[lang].name">
                        <span class="text-danger">{{ errors[`version.${lang}.name`] }}</span>
                      </div>
                    </template>
                  </MDBCol>
                  <MDBCol md="12" v-if="Form.type.value == 3">
                    <div class="mb-3">
                      <label class="form-label text-dark">綁定裝置數<span class="text-danger">*</span></label>
                      <input type="text" class="form-control" placeholder="綁定裝置數" v-model="Form.device.value">
                      <span class="text-danger">{{ deviceError }}</span>
                    </div>
                  </MDBCol>
                  
                </MDBRow>
                <MDBRow v-if="Form.type.value == 3">
                  <MDBCol md="2" >
                    <div class="mb-3">
                      <div>
                        <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.COMMODITYFREESTATUS') }}<span class="text-danger">*</span></label>  
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-free-1`" value="1" v-model="Form.free.value">
                        <label class="form-check-label" v-bind:for="`${Action}-free-1`">
                          {{ $t('COMMON.PUTON') }}
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-free-0`" value="0" v-model="Form.free.value">
                        <label class="form-check-label" v-bind:for="`${Action}-free-0`">
                          {{ $t('COMMON.PUTOFF') }}
                        </label>
                      </div>
                      <span class="text-danger">{{ freeError }}</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="2" >
                    <div class="mb-3">
                      <div>
                        <label for="exampleFormControlInput1" class="form-label text-dark">驗證帳號密碼<span class="text-danger">*</span></label>  
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-verify-1`" value="1" v-model="Form.verify.value">
                        <label class="form-check-label" v-bind:for="`${Action}-verify-1`">
                          啟用
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-verify-0`" value="0" v-model="Form.verify.value">
                        <label class="form-check-label" v-bind:for="`${Action}-verify-0`">
                          停用
                        </label>
                      </div>
                      <span class="text-danger">{{ verifyError }}</span>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
              <MDBTabPane tabId="commodity" class="pt-3">
                <MDBRow class="g-3">
                  <MDBCol md="12" >
                    <div class="mb-3">
                      <div>
                        <label for="exampleFormControlInput1" class="form-label text-dark">產品</label>  
                      </div>
                      <span class="text-danger">{{ commodityError }}</span>
                      <div class="mb-3">
                        <MDBBtn type="button" color="primary" @click="commodity_modal = true">加入產品</MDBBtn>
                        <MDBModal
                          tabindex="-1"
                          v-model="commodity_modal"
                          size="lg"
                        >
                          <MDBModalHeader>
                            <MDBModalTitle>加入商品</MDBModalTitle>
                          </MDBModalHeader>
                          <MDBModalBody>
                            <MDBContainer>
                              <MDBRow class="mb-3">
                                <MDBRow>
                                  <MDBCol md="4">
                                    <MDBInput  v-model="where.amy" />
                                  </MDBCol>
                                  <MDBCol md="4">
                                    <MDBInput  v-model="where.jason" />
                                  </MDBCol>
                                  <MDBCol md="4">
                                    <MDBInput  v-model="where.key_pro_version" label="KeyProVersion" placeholder="KeyProVersion"/>
                                  </MDBCol>
                                </MDBRow>
                                <div style="height:65vh;overflow-y: auto;">
                                  <MDBTable
                                    class="table datatable-table"
                                    responsive
                                  >
                                    <thead>
                                      <tr>
                                        <th></th>
                                        <th>序</th>
                                        <th>產品編號</th>
                                        <th>版本區域</th>
                                        <th>產品名稱</th>
                                        <th>使用版本</th>
                                        <th>KeyProVersion</th>
                                        <th>VersionNo</th>
                                        <th>NeedWrite</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <template v-if="tmp_commodity.length > 0">
                                        <tr v-for="(row ,k) in tmp_commodity" :key="k" >
                                          <td scope="row"><input type="checkbox" v-model="commoditys" :value="row.uuid"></td>
                                          <td scope="row">{{row.soft_seq_no}}</td>
                                          <td scope="row">{{row.number}}</td>
                                          <td scope="row">{{row.version_area}}</td>
                                          <td scope="row">{{row.name}}</td>
                                          <td scope="row">{{row.version}}</td>
                                          <td scope="row">{{row.key_pro_version}}</td>
                                          <td scope="row">{{row.version_no}}</td>
                                          <td scope="row">{{row.need_write}}</td>
                                        </tr> 
                                      </template>
                                      <tr v-else>
                                        <td colspan="9" class="text-center">無資料</td>
                                      </tr>
                                    </tbody>
                                  </MDBTable>
                                </div>
                              </MDBRow>
                            </MDBContainer>
                          </MDBModalBody>
                          <MDBModalFooter>
                            <MDBBtn @click="commodity_modal = false">{{ $t("COMMON.CLOSE") }}</MDBBtn>
                          </MDBModalFooter>
                        </MDBModal>
                        <template  v-for="(item ,k) in Form.commodity.value" :key="k">
                          <div class="form-check">
                            <MDBRow>
                              <MDBCol md="4">
                                <i class="fa fa-window-close text-danger" @click="remove(k)" role="button"></i>
                                {{ Commodity.find(item=>item.uuid==k).name }}
                              </MDBCol>
                              <MDBCol md="8" class="text-nowrap">
                                <MDBRow>
                                  <MDBCol md="4">
                                    <div class="mb-3">
                                      <label class="form-label text-dark">{{ $t('COMMON.COMMODITYPEOPLE') }}<span class="text-danger">*</span></label>
                                      <input type="number" class="form-control" :placeholder="$t('COMMON.COMMODITYPEOPLE')" v-model="Form.commodity.value[k].people">
                                      <!-- <span class="text-danger">{{ peopleError }}</span> -->
                                    </div>
                                  </MDBCol>
                                  <MDBCol md="4">
                                    <div class="mb-3">
                                      <label class="form-label text-dark">{{ $t('COMMON.COMMODITYCOUNT') }}<span class="text-danger">*</span></label>
                                      <input type="number" class="form-control" :placeholder="$t('COMMON.COMMODITYCOUNT')" v-model="Form.commodity.value[k].count" >
                                      <!-- <span class="text-danger">{{ countError }}</span> -->
                                    </div>
                                  </MDBCol>
                                  <MDBCol md="4">
                                    <div class="mb-3">
                                      <label class="form-label text-dark">{{ $t('COMMON.COMMODITYAUTHORIZATION') }}<span class="text-danger">*</span></label>
                                      <input type="number" class="form-control" :placeholder="$t('COMMON.COMMODITYAUTHORIZATION')" v-model="Form.commodity.value[k].authorization">
                                      <!-- <span class="text-danger">{{ authorizationError }}</span> -->
                                    </div>
                                  </MDBCol>
                                  <MDBCol md="6">
                                    <div class="mb-3">
                                      <div>
                                        <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.COMMODITYAUTHORIZATIONSERVER') }}<span class="text-danger">*</span></label>  
                                      </div>
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-free-1`" value="1" v-model="Form.commodity.value[k].authorization_server">
                                        <label class="form-check-label" v-bind:for="`${Action}-authorization_server-1`">
                                          {{ $t('COMMON.YES') }}
                                        </label>
                                      </div>
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-authorization_server-0`" value="0" v-model="Form.commodity.value[k].authorization_server">
                                        <label class="form-check-label" v-bind:for="`${Action}-authorization_server-0`">
                                          {{ $t('COMMON.NO') }}
                                        </label>
                                      </div>
                                      <!-- <span class="text-danger">{{ authorization_serverError }}</span> -->
                                    </div>
                                  </MDBCol>
                                  <MDBCol md="6">
                                    <div class="mb-3">
                                      <div>
                                        <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.COMMODITYAUTO') }}<span class="text-danger">*</span></label>  
                                      </div>
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-auto-1`" value="1" v-model="Form.commodity.value[k].auto">
                                        <label class="form-check-label" v-bind:for="`${Action}-auto-1`">
                                          {{ $t('COMMON.YES') }}
                                        </label>
                                      </div>
                                      <div class="form-check">
                                        <input class="form-check-input" type="radio" v-bind:id="`${Action}-auto-0`" value="0" v-model="Form.commodity.value[k].auto">
                                        <label class="form-check-label" v-bind:for="`${Action}-auto-0`">
                                          {{ $t('COMMON.NO') }}
                                        </label>
                                      </div>
                                      <!-- <span class="text-danger">{{ autoError }}</span> -->
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </template>
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
              <MDBTabPane tabId="price" class="pt-3">
                <MDBRow class="g-3">
                  <MDBCol md="12">
                    <label>訂閱方案</label>
                  </MDBCol>
                  <MDBCol md="12">
                    <template v-for="(currency,k) in Form.currency.value" :key="k">
                      <MDBRow class="mb-3" v-if="(typeof Form.currency.value[k] != 'undefined')">
                        <MDBCol md="12" class="text-start">
                          <label>{{ $t(`CURRENCY.${k}`) }}({{k}})</label>
                        </MDBCol>
                        <template v-for="(item,j) in currency" :key="j">
                          <MDBRow class="mb-3" v-if="(Form.type.value !=2 || j==1)">
                            <MDBCol md="3" class="d-flex justify-content-end align-self-center" >
                              <span v-if="Form.type.value ==2">報名費</span>
                              <span v-else-if="(['6','7'].indexOf(j) >= 0)">{{ item.name }}/天數</span>
                              <span v-else>{{ item.name }} <span> / {{$t("COMMON.MONTH")}} </span></span>
                            </MDBCol>
                            <MDBCol md="3">
                              <MDBInput
                                :label="(['6','7'].indexOf(j) >= 0)?`天數`:$t(`COMMON.AMOUNT`)"
                                type="number"
                                v-model="Form.currency.value[k][j].amount"
                              />
                            </MDBCol>
                            <MDBCol md="2" class="d-none">
                              <div class="form-check">
                                <input class="form-check-input" type="radio" v-bind:id="`${Action}-${k}-${j}-currency_status-1`" value="1" v-model="Form.currency.value[k][j].status">
                                <label class="form-check-label" v-bind:for="`${Action}-${k}-${j}-currency_status-1`">
                                  {{ $t('COMMON.PUTON') }}
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" v-bind:id="`${Action}-${k}-${j}-currency_status-0`" value="0" v-model="Form.currency.value[k][j].status">
                                <label class="form-check-label" v-bind:for="`${Action}-${k}-${j}-currency_status-0`">
                                  {{ $t('COMMON.PUTOFF') }}
                                </label>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </template>
                      </MDBRow>
                    </template>
                  </MDBCol>
                  <MDBCol md="12">
                    <template v-for="(type,k) in Currency" :key="k">
                      <div v-if="(typeof Form.currency.value[type] == 'undefined')">
                        <a href="javascript:void(0)" @click="AddCurrency(type)"><MDBIcon icon="plus" iconStyle="fas" />{{ $t(`CURRENCY.${type}`) }} ({{type}})</a>
                      </div>  
                    </template>
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>
        </MDBCardText>
        <MDBBtn color="primary" type="button" @click="Submit" v-if="action == 'insert' || this.action == 'copy'" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn color="primary" type="button" @click="EditSubmit" v-if="action == 'update'" :disabled="SubmitLoading">
          {{ $t("COMMON.SAVE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="CloseModel">
          {{ $t("COMMON.CLOSE") }}
        </MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBInput,  MDBRow, MDBBtn, MDBCol, MDBIcon, MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBModalFooter, MDBModal, MDBModalBody, MDBModalHeader, MDBContainer, MDBModalTitle, MDBTable, MDBTabs, MDBTabContent, MDBTabPane, MDBTabNav, MDBTabItem } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service.js";
import { mapGetters } from "vuex";

export default {
  name: "PackageCommodityForm",
  components: {
    MDBInput,
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText,
    MDBModalFooter,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBContainer,
    MDBModalTitle,
    MDBTable,
    MDBTabs,
    MDBTabContent,
    MDBTabPane,
    MDBTabNav,
    MDBTabItem
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    model: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    },
    // currency: {
    //   type: [Array,Object],
    //   default: function() {
    //     return ["TWD","USD","CNY"];
    //   }
    // }
  },
  data() {
    return {
      languages: i18nService.languages,
      language: i18nService.getActiveLanguage(),
      commodity_modal: false,
      categorys: {},
      commodity: {
          1: this.$i18n.t("COMMON.ONCE"),
          2: this.$i18n.t("COMMON.MONTH1"),
          3: this.$i18n.t("COMMON.MONTH3"),
          4: this.$i18n.t("COMMON.MONTH6"),
          5: this.$i18n.t("COMMON.MONTH12"),
          6: this.$i18n.t("COMMON.FREE"),
          7: this.$i18n.t("COMMON.FREE") + "(已綁定會員)",
      },
      Multiselect: {
        searchable : true,
      },
      where: {
        amy: "",
        jason: "",
        key_pro_version: "",
      },
      tmp_commodity: []
    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.model,
      set: val => emit("update:model", val)
    });

    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });

    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const GetTable = inject("GetTable");
    const SubmitLoading = ref(false);
    const commoditys = ref([]);
    const i18n = useI18n();
    const page = ref("general");
    var mutilpleLangsNmaeValue = {};
    var mutilpleLangsVersionValue = {};
    const mutilpleLangsNmae = {};
    const mutilpleLangsVersion = {};

    for(let k in i18nService.languages) {
      mutilpleLangsNmae[i18nService.languages[k].lang] = yup.object({ name: yup.string().required().label("商品名稱")});
      mutilpleLangsVersion[i18nService.languages[k].lang] = yup.object({ name: yup.string().required().label((i18n.t(`LANG.${i18nService.languages[k].lang}`)+i18n.t(`COMMON.NAME`)))});
      mutilpleLangsNmaeValue[i18nService.languages[k].lang] = { name: ""};
      mutilpleLangsVersionValue[i18nService.languages[k].lang] = { name: ""};
    }

    const basicSchema = {
      name: yup.object().shape(mutilpleLangsNmae).label(i18n.t("COMMON.PRODUCTNAME")),
      version: yup.object().shape(mutilpleLangsVersion).label(i18n.t("COMMON.USEVERSION")),
      currency: yup.object().label(i18n.t("COMMON.COMMODITYCURRENCY")),
      status: yup.number().required().label(i18n.t("COMMON.COMMODITYSTATUS")),
      free: yup.number().required().label(i18n.t("COMMON.COMMODITYFREESTATUS")),
      sort: yup.number().required().label(i18n.t("COMMON.SORT")),
      device: yup.number().required().label("綁定裝置數"),
      commodity: yup.object().label(i18n.t("COMMON.COMMODITYCURRENCY")),
      type: yup.number().required().label(i18n.t("COMMON.PRODUCTTYPE")),
      verify: yup.number().required().label("帳號密碼驗證"),
      product_name: yup.string(),
    };

    let ExtendSchema = {};
    
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));
    const { handleSubmit, resetForm, errors } = useForm({
      validationSchema: FormSchema,
      initialValues: {
        product_name: "",
        status: 1,
        currency: {},
        free: 0,
        sort: 0,
        commodity: {},
        type: 3,
        verify: 0,
        device: 1,
        name: {...mutilpleLangsNmaeValue},
        version: {...mutilpleLangsVersionValue},
      }
    });

    const { value: name, errorMessage: nameError } = useField('name');
    const { value: version, errorMessage: versionError } = useField('version');
    const { value: currency, errorMessage: currencyError } = useField('currency');
    const { value: status, errorMessage: statusError } = useField('status');
    const { value: free, errorMessage: freeError } = useField('free');
    const { value: sort, errorMessage: sortError } = useField('sort');
    const { value: commodity, errorMessage: commodityError } = useField('commodity');
    const { value: device, errorMessage: deviceError } = useField('device');
    const { value: type, errorMessage: typeError } = useField('type');
    const { value: verify, errorMessage: verifyError } = useField('verify');
    const { value: product_name } = useField('product_name');
    for(let k in i18nService.languages) {
      useField(`name.${i18nService.languages[k].lang}.name`);
      useField(`version.${i18nService.languages[k].lang}.name`);
    }

    const Form = {
      name,
      version,
      currency,
      status,
      free,
      sort,
      commodity,
      device,
      type,
      verify,
    };

    const Submit = handleSubmit(values => {
      SubmitLoading.value = true;
      ApiService.post("/Admin/v1/PackageCommodity", values).then(response => {
        SubmitLoading.value = false;
        if (response.status == 200) {
          CommonService.AxiosHandle(response);
          resetForm();
          Model.value = false;
          GetTable();
        }
      });
    });

    const EditSubmit = handleSubmit(values => {
      SubmitLoading.value = true;
      ApiService.update("/Admin/v1/PackageCommodity",props.uuid ,values).then(response => {
        SubmitLoading.value = false;
        if (response.status == 200) {
          CommonService.AxiosHandle(response);
          resetForm();
          Model.value = false;
          FormUUid.value = "";
          GetTable();
        }
      });
    });

    const CloseModel = function() {
      FormUUid.value = "";
      Model.value = false;
      resetForm();
    };

    return {
      Model,
      Action,
      Form,
      versionError,
      nameError,
      statusError,
      currencyError,
      freeError,
      sortError,
      commodityError,
      deviceError,
      typeError,
      verifyError,
      Submit,
      EditSubmit,
      resetForm,
      FormUUid,
      SubmitLoading,
      commoditys,
      page,
      product_name,
      errors,
      CloseModel,
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    },
    "Form.free.value": function(val) {
      let keys = Object.keys(this.Form.currency.value);
      for(let i = 0;i < keys.length; i++) {
        if(typeof(this.Form.currency.value[keys[i]][6]) == 'undefined') {
          this.Form.currency.value[keys[i]][6] = {
            amount: 0,
            status: val,
          };  
        }else {
          this.Form.currency.value[keys[i]][6].status = val;  
        }
      }
    },
    commoditys: function(val) {
      val.map((item) => {
        if(typeof this.Form.commodity.value[item] =='undefined') {
          this.Form.commodity.value[item] = {
            'people': 1,
            'count': 99,
            'authorization': 0,
            'authorization_server': 0,
            'auto': 0,
          }
        }
      });
      Object.keys(this.Form.commodity.value).filter(x => !val.includes(x)).map(i=>delete this.Form.commodity.value[i]);
    },
    "where.amy": function() {
      this.commodityFilter();
    },
    "where.jason": function() {
      this.commodityFilter();
    },
    "where.key_pro_version": function() {
      this.commodityFilter();
    },
    "product_name": function(val) {
      if(val) {
        for(let lang in this.Form.name.value){
          this.Form.name.value[lang].name = val;
        }
      }
    },
  },
  inject: ["Currency","Commodity"],
  methods: {
    commodityFilter() {
      var key_pro_version = new RegExp(this.where.key_pro_version);
      var amy = new RegExp(this.where.amy);
      var jason = new RegExp(this.where.jason);
      this.tmp_commodity = this.Commodity.filter((item) => {
        let amy_result = true;
        let jason_result = true;
        if(this.where.amy) {
          amy_result = (item.name.match(amy) || item.number.match(amy) || item.version.match(amy) || item.soft_seq_no.match(amy)) ;
        }
        if(this.where.jason) {
          jason_result = (item.name.match(jason) || item.number.match(jason) || item.version.match(jason) || item.soft_seq_no.match(jason)) ;
        }
        return amy_result && jason_result && item.key_pro_version.match(key_pro_version);
      });
    },
    GetDetail() {
      this.resetForm();
      Promise.all([
        new Promise(resolve => {
          this.commoditys = [];
          ApiService.get("/Admin/v1/PackageCommodity", this.FormUUid).then(response => {
            if (response.status == 200) {
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "name":
                      // for(let lang in response.data.data.name){
                      //   this.Form.name.value[lang].name = response.data.data.name[lang].name;
                      // }
                      this.product_name = response.data.data.name['tw'].name;
                    break;
                    case "version":
                      if(typeof response.data.data.version == 'object') {
                        console.log(response.data.data.version);
                        console.log(this.Form.version.value);
                        for(let lang in response.data.data.version){
                          this.Form.version.value[lang].name = response.data.data.version[lang].name;
                        }
                      }else{
                        this.Form.version.value['tw'].name = response.data.data.version;
                      }
                      
                    break;
                    case "currency":
                      var tmp = {};
                      response.data.data.currency.map(item => {
                        if(typeof tmp[item.currency] == "undefined") {
                          tmp[item.currency] = {
                            1: { name: this.commodity[1],amount: "0",status: 1 },
                            2: { name: this.commodity[2],amount: "0",status: 1 },
                            3: { name: this.commodity[3],amount: "0",status: 1 },
                            4: { name: this.commodity[4],amount: "0",status: 1 },
                            5: { name: this.commodity[5],amount: "0",status: 1 },
                            6: { name: this.commodity[6],amount: "0",status: this.Form.free.value },
                            7: { name: this.commodity[7],amount: "0",status: this.Form.free.value },
                          };
                        }
                        tmp[item.currency][item.category.id] = {
                          name: this.commodity[item.category.id],
                          status: item.status,
                          amount: (+item.amount)?item.amount:"",
                        };
                      });
                      Object.assign(this.Form.currency.value,tmp);
                    break;
                    case "commodity":
                      response.data.data.commodity.map( (item) => {
                        this.commoditys.push(item.uuid);
                        this.Form.commodity.value[item.uuid] = {
                          'people': item.pivot.people,
                          'auto': item.pivot.auto,
                          'count': item.pivot.count,
                          'authorization': item.pivot.authorization,
                          'authorization_server': item.pivot.authorization_server,
                        }
                      });
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    },
    AddCurrency(type) {
      let tmp = {};
      tmp[type] = {
        1: { name: this.commodity[1],amount: "0",status: 1 },
        2: { name: this.commodity[2],amount: "0",status: 1 },
        3: { name: this.commodity[3],amount: "0",status: 1 },
        4: { name: this.commodity[4],amount: "0",status: 1 },
        5: { name: this.commodity[5],amount: "0",status: 1 },
        6: { name: this.commodity[6],amount: "0",status: this.Form.free.value },
        7: { name: this.commodity[7],amount: "0",status: this.Form.free.value },
      };
      Object.assign(this.Form.currency.value,tmp);
    },
    DeleteDownload(index){
      this.Form.downloads.value = this.Form.downloads.value.filter(function(item,key) {
        return key !== index;
      })
    },
    
    remove(uuid) {
      delete this.commoditys[this.commoditys.indexOf(uuid)];
      delete this.Form.commodity.value[uuid];
    }
  },
  mounted: function() {
    this.tmp_commodity = this.Commodity;
  },
  computed: {
    ...mapGetters(["getFree"]),
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>