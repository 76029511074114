<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog || copy_dialog )}">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
    <MDBRow>
      <MDBCol md="2">
        <MDBInput  v-model="where.amy" />
      </MDBCol>
      <MDBCol md="2">
        <MDBInput  v-model="where.jason" />
      </MDBCol>
      <MDBCol class="mb-2 text-end">
        <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
        <MDBBtn color="info" @click="add_dialog = true">{{ $t("PACKAGE-COMMODITY-LIST.ADD-ITEM") }}</MDBBtn>  
      </MDBCol>
    </MDBRow>
		<DataTable
			:header="headers"
			:tabledata="desserts"
			v-model:total="total"
			v-model:options="options"
			v-model:loading="loading"
		>
      <template v-slot:name="{ item }">
          {{item.name['tw'].name}}
      </template>
      <template v-slot:version="{ item }">
        <template v-if="typeof item.version == 'object'"> 
          <div v-for="(v, k) in item.version" :key="k">
            {{ $t(`LANG.${k}`) + " : " + v.name }}  
          </div>
        </template>
        <template v-else>
          {{item.version}}
        </template>
      </template>
			<!-- 時間轉換 -->
      <template v-slot:created_at="{ item }">
        {{ Common.TimeFormat(item.created_at) }}
      </template>
      <template v-slot:status="{ item }">
        {{ $t("MEMBER.STATUS_" + item.status) }}
      </template>
      <template v-slot:type="{ item }">
        {{ $t(`COMMON.COMMODITYTYPE_${item.type}`) }}
      </template>
      <!-- 操作template -->
      <!-- <template v-slot:uuid="{ item }">
				<a @click="GetDetail(item.uuid)" data-bs-toggle="tooltip" :title="$t('PACKAGE-COMMODITY-LIST.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
					<MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
				</a>
				<a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('PACKAGE-COMMODITY-LIST.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
					<MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
				</a>
      </template> -->
      <template v-slot:copy="{ item }">
        <MDBBtn color="success" @click="CopyUUid = item.uuid" class="px-3 py-2">
          <MDBIcon icon="copy" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
      <template v-slot:edit="{ item }">
        <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
          <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
      <template v-slot:uuid="{ item }">
        <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
          <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
		</DataTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
	<PackageCommodityForm v-model:model="add_dialog" action="insert"></PackageCommodityForm>
  <PackageCommodityForm v-model:model="edit_dialog" action="update" v-model:uuid="EditUUid"></PackageCommodityForm>
  <PackageCommodityForm v-model:model="copy_dialog" action="copy" v-model:uuid="CopyUUid"></PackageCommodityForm>
</template>

<style scoped>
  .sub-category {
    list-style: auto;
    text-align: start;
  }
  .sub-category li {
    border-bottom: 1px solid;
    width: fit-content;
  }
  .sub-div {
    display: inline-block;
    margin-right: 10px;
  }
</style>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBCardBody, MDBCard, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import PackageCommodityForm from '@/components/Form/Commodity/PackageCommodityForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import i18nService from "@/core/services/i18n.service.js";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from 'vue';

export default {
  name: "PackageCommodityList",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		PackageCommodityForm,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBCardBody,
    MDBCard,
    PageLoading,
    MDBInput
  },
  data() {
    return {
      add_dialog: false,
      edit_dialog: false,
      copy_dialog: false,
      add_sub_dialog: false,
      edit_sub_dialog: false,
      loading_dailog: false,
      loading: true,
      EditUUid: "",
      CopyUUid: "",
      AddSubUUid: "",
      DeleteUUid: "",
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["created_at"],
        sortDesc: ['created_at'],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      },
      headers: [
        {
          text: this.$i18n.t("COMMON.SEQUENCE"),
          align: "start",
          sortable: false,
          value: "no",width: "50px" ,class: "text-center"
        },
        { text: this.$i18n.t("COMMON.NAME"), value: "name" },
        { text: this.$i18n.t("COMMON.VERSION"), value: "version" },
        // { text: this.$i18n.t("COMMON.COMMODITYPEOPLE"), value: "people" },
        // { text: this.$i18n.t("COMMON.COMMODITYCOUNT"), value: "count" },
        // { text: this.$i18n.t("COMMON.COMMODITYAUTHORIZATION"), value: "authorization" },
        // { text: this.$i18n.t("COMMON.COMMODITYAUTHORIZATIONSERVER"), value: "authorization_server" },
        // { text: this.$i18n.t("COMMON.COMMODITYAUTO"), value: "auto" },
        { text: this.$i18n.t("COMMON.CREATE_AT"), value: "created_at" },
        { text: this.$i18n.t("COMMON.COPY"), value: "copy", sortable: false ,width: "50px" ,class: "text-center"},
        { text: this.$i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,width: "50px" ,class: "text-center"},
        { text: this.$i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,width: "50px" ,class: "text-center"}
      ],
      desserts: [],
      total: 0,
      Common: CommonService,
      ProductCategory: [],
      language: {
        items: i18nService.languages,
        selected: "",
      }
    };
  },
  setup() {
    const PageLoading = ref(true);
    const currency = reactive([]);
    const Commodity = reactive([]);
    const where = reactive({
      type: "",
      amy: "",
      jason: "",
    });
    const GetCommodity = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Commodity",
          {
            params: Object.assign(CommonService.SortHandleObject({
              page: 1,
              itemsPerPage: -1,
              sortBy: ["created_at"],
              sortDesc: []
            }),{status:1,type:1})
          }
        ).then(response => {
          if (response.status == 200) {
            response.data.data.data.map(commodity => {
              Commodity.push(commodity);
            });
            resolve(response);
          }
        });
      });
    };

		return {
      PageLoading,
      currency,
      GetCommodity,
      Commodity,
      where
    };
  },
  created() {
    this.GetTable();
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  },
  provide () {
    return {
      GetTable: this.GetTable,
      Currency: this.currency,
      Commodity: this.Commodity,
    }
  },
  methods: {
		GetTable() {
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query(
          "/Admin/v1/PackageCommodity",
          {
            params: Object.assign(CommonService.SortHandleObject(this.options),{},this.where)
          }
        ).then(response => {
          if (response.status == 200) {
            this.desserts = CommonService.DataNoHandle(
              response.data.data.data,
              this.options.page,
              this.options.itemsPerPage
            );
            this.total = response.data.data.total;
            resolve(response);
          }
          this.loading = false;
        });
      });
    },
    GetCurreny() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/currency/online",
          {
            params: {
              status: 1
            }
          }
        ).then(response => {
          if (response.status == 200) {
            response.data.data.map((item) => {this.currency.push(item.currency);});
            // this.currency = response.data.data.map(item => item.currency);
            resolve(response);
          }
        });
      });
    },
    DeleteAction(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/PackageCommodity/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                this.GetTable();
              }
            }
          );
        }
      });
    }
  },
  mounted() {
    Promise.all([
      this.GetCurreny(),
      this.GetCommodity(),
    ]).then(() => {
      this.PageLoading = false;
    }).catch(error => {
      console.log(error);
    });
  },
};
</script>